/**
 * Activity core module
 */
export default {

  send (payload) {
    try {
      window.dataLayer && window.dataLayer.push(payload);
    } catch (error) {
      ConsultaDadosIcon.log('Erro ao enviar [Activity]', error)
    }
  },
};
