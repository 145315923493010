<script setup>

import { computed } from 'vue';

import Typography from '@/components/base/Typography';
import ExpandAccordionIcon from '@/components/icons/ExpandAccordionIcon.vue';
import ExpandAccordionIconLegacy from '@/components/icons/ExpandAccordionIconLegacy.vue';
import Activity from '@/core/activity';

defineEmits(['panel-expand']);

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  open: {
    type: Boolean,
    default: false,
  },
  tracker: {
    type: String,
    default: '',
  },
  isDesktop: {
    type: Boolean,
    default: false,
  },
  offersInfo: {
    type: Object,
    required: false,
    default: () => {},
  },
  offerType: {
    type: String,
    required: false
  }
});

const isOpen = computed(() => props.open);

const iconRotation = computed(() => {
  const open = 'transform: rotate(-180deg); -webkit-transform: rotate(-180deg)';
  const closed = '';

  return isOpen.value ? open : closed;
});

</script>


<script>
export default {
  methods: {
    sendEvent () {
      Activity.send(
        {
          event: 'select_content',
          content_type: 'botao',
          creative_slot: 'empreendimento',
          creative_name: this.tracker,
          level_name: 'lead',
        },
      );
    }
  }
}
</script>

<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-row items-center gap-2">
      <!-- Header -->
      <div v-if="isDesktop">
        <div class="legacy-title" v-if="title !== ''">
          {{ title }}
        </div>
        <span v-if="offersInfo && offersInfo.title" id="previous-offers" class="offers-info">
          <span class="offers-title offers-border-gray" />
          <Typography
            color="neutral-grey-5"
            variant="title-medium"
            tag="span"
            class="type-offer"
          >
            {{ offersInfo.title }}
          </Typography>
          <span class="pipe"/>
          <Typography
            color="neutral-grey-5"
            variant="body-medium"
            tag="span"
            class="type-offer"
          >
          {{ offersInfo.description }}
          </Typography>
        </span>
      </div>
      <div v-else>
        <div v-if="offersInfo && offersInfo.title">
          <Typography
            color="neutral-grey-6"
            variant="title-large-secondary"
            tag="div"   
          >
            {{offersInfo.title}}
          </Typography>
        </div>

        <Typography v-if="title !== ''"
          color="brand-dark"
          variant="title-large-secondary"
          tag="div"
        >
          {{ title }}
        </Typography>
      </div>
      <div class="flex-grow w-0 h-0">
        <div
          class="border-t border-solid"
          style="border-color: #D3D6DE;"
        />
      </div>
      <div
        id="collapse-chevron"
        @click="() => {
          $emit('panel-expand')
          if (!isOpen) {
            sendEvent()
          }
        }"
      >
        <ExpandAccordionIcon
          v-if="!isDesktop"
          :style="iconRotation"
          class="rotatable"
        />
        <ExpandAccordionIconLegacy
          v-else
          :style="iconRotation"
          class="rotatable"
          :offerType="offerType"
        />
      </div>
    </div>
    <div v-if="!isDesktop" class="mb-4">
      <Typography
        v-if="offersInfo && offersInfo.description"
        color="neutral-grey-6"
        variant="body-medium"
        tag="div"
      >
        {{ offersInfo.description }}
      </Typography>
    </div>
    <div v-if="isOpen" :class="!isDesktop ? 'mobile-flex' : ''">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offers-info {
  align-items: center;
  display: flex;
  gap: 9px;
}
.mobile-flex {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.rotatable {
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: transform;
  cursor: pointer;
}
.legacy-title {
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-size: 22.5px;
  line-height: 22px;
  color: #3366CC;
  margin-bottom: 8px;
}
.pipe {
  border: 0;
  border-left: 1px solid #6B6B6B;
  height: 20px;
}
.offers-title {
  display: inline-block;
  width: 8px;
  height: 35px;

  &.offers-border-blue {
    background-color: #044DA1;
  }
  &.offers-border-green {
    background-color: #006141;
  }
  &.offers-border-black {
    background-color: var(colors-neutral-black);
  }
  &.offers-border-gray {
    background-color: #6B6B6B;
  }
}
</style>