<template>
  <div class="multi-select">
    <button
      v-for="(button, index) in buttons"
      :key="index"
      class="multi-select__option"
      :class="{ 'multi-select__option--selected': button.value === selected }"
      :disabled="disabled"
      @click="selectButton(button.value)"
    >
      {{ button.label }}
    </button>
  </div>
</template>

<script>

export default {

  name: 'ButtonGroup',

  model: {
    prop: 'value',
    event: 'input',
  },
  
  props: {

    value: {
      type: String,
      default: '',
    },

    buttons: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    defaultSelection: {
      type: String,
      default: null,
    },
  },

  data () {
  
    return {
      selected: null,
       isDisabled: this.disabled,
    };
  },

  watch: {

    value (newValue) {

      this.selectButton(newValue);
    },
     disabled(newVal) {
      this.isDisabled = newVal;
    },
  },

  mounted () {
  
    this.selectButton(this.defaultSelection);
  },

  methods: {

    selectButton (value) {

      this.selected = value;

      this.$emit('input', value);
    },
  },
};

</script>

<style lang="scss" scoped>

.multi-select {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 9999px;
  background: rgba(126, 146, 206, 0.1);
  color: rgba(57, 65, 90, 1);
  padding: 6px;

  &__option {
    border-radius: 9999px;
    padding: 13px 45px 14px 45px;
    transition: all .2s ease-out;
    line-height: 17px;

    &--selected {
      background: rgba(203, 208, 228, 1);
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      background: rgba(221, 221, 221, 1);
      color: rgba(255, 255, 255, 1);
    }

    &:hover {
      background: rgba(255, 255, 255, 1);
      color: rgba(48, 58, 86, 1);
      box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0);
    }
  }
}

</style>
