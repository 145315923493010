<script setup>

import { computed, inject, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';

import Typography from '@/components/base/Typography';

import SmallButton from '@/components/mobile/SmallButton';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import LGPDModal from '@/components/LGPD/LGPDModal.vue';
import SearchFilters from '@/components/search/SearchFilters.vue';
import MaintenanceIcon from '@/components/icons/Maintenance.vue';
import Button from '@/components/base/Button';
import ExpandablePanel from '@/components/accordion/ExpandablePanel.vue';

import Lead from '@/views/mobile/LeadSearch.vue';
import OfferCard from '@/views/mobile/OfferCard.vue';
import QuotaNotification from '@/views/mobile/QuotaNotification.vue';
import IPTU from '@/views/mobile/Condominium/IPTU.vue';
import Characteristics from '@/views/mobile/Condominium/Characteristics.vue';
import NotFound from '@/components/Messages/NotFound.vue';
import Loading from '@/components/Loadings/LoadingText.vue';
import { formatCurrency } from '@/utils';
import Activity from '@/core/activity';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import SearchIcon from '../../../components/icons/SearchIcon.vue';
import AnalyticsSmall from '../../../components/icons/AnalyticsSmall.vue';
import BlockedInfoMessage from '../../../components/LGPD/BlockedInfoMessage.vue';

defineProps(['step']);

const router = useRouter();
const route = useRoute();
const vuexStore = inject('vuex-store');

const transactionsModal = ref(false);
const transactionUnit = ref([]);

const quotaNotificationDisplay = ref(false);

const filteredResults = ref([]);

const email = ref('suportecidadevirtual@bossanovasir.com.br');

const panel = ref({
  owners: {
    open: false,
  },
  transactions: {
    open: false,
  },
  previousOffers: {
    open: false,
  },
  potentialOffers: {
    open: false,
  },
});

const filters = ref({
  orderBy: 'minDistance',
  withOffers: false,
  keys: {
    distance: 'distance_from_center',
    offers: 'offersCount',
    value: 'avgValue',
  },
});

const offersMax = ref({
  bossaOffers: 4,
  newOffers: 4,
  lastMonthOffers: 4,
});

const offerMin = 4;

const offersInfo = ref ({
  bossaOffers: {
    title: 'Ofertas Bossa Nova Sotheby\'s',
    description: 'Ofertas Bossa Nova disponíveis no último mês',
  },
  lastMonthOffers: {
    title:'Permaneceram',
    description: 'Ofertas que aparecem no último e no penúltimo mês',
  },
  newOffers: {
    title: 'Novidades',
    description: 'Ofertas do último mês que não apareceram no mês anterior',
  },
  previousOffers: {
    title: 'Ofertas anteriores',
    description: 'Ofertas que não apareceram no último mês',
  },
  potentialOffers: {
    title: 'Possíveis ofertas',
    description: 'Ofertas na mesma rua com características semelhantes',
  },
});

const leadPermit = computed(() => {
  return vuexStore.getters['user/leadPermit'];
});

const resultsIsLoading = computed(() => {
  return vuexStore.getters[`${route.name}/isLoading`];
});

const summaryIsLoading = computed(() => {
  return vuexStore.getters['summary/isLoading'];
});

const propertyIsLoading = computed(() => {
  return vuexStore.getters['property/mobilePropertyLoading'];
});

const typologiesIsLoading = computed(() => {
  return vuexStore.getters['property/mobileTypologiesLoading'];
});

const offersIsLoading = computed(() => {
  return vuexStore.getters['property/mobileOffersLoading'];
});

const ownersIsLoading = computed(() => {
  return vuexStore.getters['property/mobileOwnersLoading'];
});

const result = computed(() => {
  return vuexStore.getters[`${route.name}/result`];
});

const lastSearch = computed(() => {
  return vuexStore.getters[`${route.name}/lastSearch`];
});

const summary = computed(() => {
  return vuexStore.getters['summary/summary'];
});

const owners = computed(() => {
  return vuexStore.getters['property/owners'];
});

const typologies = computed(() => {
  return vuexStore.getters['property/typologies'];
});

const property = computed(() => {
  return vuexStore.getters['property/property'];
});

const offers = computed(() => {
  return vuexStore.getters['property/offers'];
});

const transactions = computed(() => {
  const transactionsList = vuexStore.getters['property/transactions'];
  const bundledTransactions = {};

  const sortableList = transactionsList.map(transaction => {
    return {
      ...transaction,
      date: new Date(transaction.date),
    };
  });

  for (const transaction of sortableList) {
    const { units } = transaction;

    if (!bundledTransactions[units]) {
      bundledTransactions[units] = [];
    }

    bundledTransactions[units].push(transaction);
  }

  return new Array.from(Object.values(bundledTransactions)).sort((a, b) => b[0].date - a[0].date);
});

const transactionsLoading = computed(() => {
  return vuexStore.getters['property/transactionsLoading'];
});

const bossaOffers = computed(() => {
  return offers.value ? offers.value.filter(offer => offer.class === 0) : [];
});

const newsOffers = computed(() => {
  return offers.value ? offers.value.filter(offer => offer.class === 10) : [];
});

const remainedOffers = computed(() => {
  return offers.value ? offers.value.filter(offer => offer.class === 20) : [];
});

const previousOffers = computed(() => {
  return offers.value ? offers.value.filter(offer => offer.class === 30) : [];
});

const potentialOffers = computed(() => {
  return offers.value ? offers.value.filter(offer => offer.class === 40) : [];
});

const quota = computed(() => vuexStore.getters['user/consultaDadosQuota']);

const sqlFormatter = (sql) => {
  const numericalPart = sql.split('-')[0];
  const verifyingDigit = sql.split('-')[1];

  const setor = numericalPart.substring(0, 3);
  const quadra = numericalPart.substring(3, 6);
  const lote = numericalPart.substring(6, 10);

  return `${setor}.${quadra}.${lote}-${verifyingDigit}`;
};

function getFilteredResults (results) {
  if (!results || !results.length) return [];

  let orderBy;
  let minToMax = true;

  switch (filters.value.orderBy) {
  case 'minDistance':
    orderBy = filters.value.keys.distance;
    minToMax = true;
    break;
  case 'maxDistance':
    orderBy = filters.value.keys.distance;
    minToMax = false;
    break;
  case 'minOffers':
    orderBy = filters.value.keys.offers;
    minToMax = true;
    break;
  case 'maxOffers':
    orderBy = filters.value.keys.offers;
    minToMax = false;
    break;
  case 'minValue':
    orderBy = filters.value.keys.value;
    minToMax = true;
    break;
  case 'maxValue':
    orderBy = filters.value.keys.value;
    minToMax = false;
    break;
  default:
    orderBy = filters.value.keys.distance;
    minToMax = true;
  }

  let newResults = [...results].sort((first, second) => {
    const firstValue = first[orderBy];
    const secondValue = second[orderBy];

    if (minToMax) {
      return firstValue - secondValue;
    }

    return secondValue - firstValue;
  });

  if (filters.value.withOffers) {
    newResults = newResults.filter(item => item[filters.value.keys.offers]);
  }

  return newResults;
}

function onSearchFilterWithOffers (withOffers) {
  filters.value.withOffers = withOffers;
  filteredResults.value = getFilteredResults(Object.values(result.value).filter(item => !!item.addressLine1));
  vuexStore.dispatch('search/setShowWithoutOffers', !withOffers);
}

function onSearchFilterOrderBy (orderBy) {
  filters.value.orderBy = orderBy;
  filteredResults.value = getFilteredResults(Object.values(result.value).filter(item => !!item.addressLine1));
}

function fetchProperty (addressId, adsIds, condoId) {
  vuexStore.dispatch('property/fetchProperty', {
    addressId,
    domain: 'buyer',
  });

  if (leadPermit.value) {
    vuexStore.dispatch('property/fetchOwners', {
      addressId,
      domain: 'lead',
    });
  }

  vuexStore.dispatch('property/fetchTransactions', {
    condominium: condoId,
    domain: 'buyer',
  });

  vuexStore.dispatch('property/fetchTypologies', {
    addressId,
    domain: 'buyer',
    attributes: lastSearch.value[1],
  });

  vuexStore.dispatch('property/fetchOffers', {
    addressId,
    context: 'buyer',
    adsIds,
  });

  router.push('/mobile/condominium/detail');
}

function showTransactionsModal (unit) {
  transactionsModal.value = true;
  transactionUnit.value = unit;
}

function hideTransactionsModal () {
  transactionsModal.value = false;
  transactionUnit.value = [];
}

watch(result, (_newValue, _oldValue) => {
  if (_newValue) {
    filteredResults.value = getFilteredResults(Object.values(result.value).filter(item => !!item.addressLine1));
  }
});

watch(route, async (newPath, _oldPath) => {
  if (newPath.path.includes('list')) {
    for (const panelKey of Object.keys(panel.value)) {
      panel.value[panelKey].open = false;
    }
  }

  if (newPath.path.includes('list') || (newPath.path.includes('detail') && !newPath.hash)) {
    const mainContentDiv = window.document.querySelector('#main-content');

    setTimeout(() => {
      if (mainContentDiv.scrollTop > 0) {
        mainContentDiv.scrollTo({
          top: -1,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }
});

watch(offers, () => {
  offersMax.value.bossaOffers = 4;
  offersMax.value.newOffers = 4;
  offersMax.value.lastMonthOffers = 4;
});

function ownerSearch (owner) {
  if (parseInt(quota.value.available)) {
    return router.push(`/mobile/person-data/search?owner=${owner}`);
  }

  quotaNotificationDisplay.value = true;
}

setTimeout(() => {
  if (document.querySelector('.v-overlay--active')) {
    document.querySelector('.v-overlay--active').style['z-index'] = 1001;
    document.querySelector('.v-dialog__content.v-dialog__content--active').style['z-index'] = 1002;
    document.querySelector('.lgpd-modal').style['z-index'] = 1003;
  }
}, 500);

const condominiumPageHeight = computed(() => {
  return route.path.includes('condominium') ? 'auto' : '0';
});

function address () {
  const addressData = [
    property.value.addressLine1,
    property.value.addressLine2,
    property.value.addressLine3,
  ].filter(addressLine => (addressLine !== undefined && addressLine !== null));

  return addressData.join(', ');
};

async function seeAds (offerId, adsIds, typology) {
  await vuexStore.dispatch('property/setLastOfferTypology', typology);

  const response = await vuexStore.dispatch('property/getAds', { offerId, adsIds, context: 'lead' });

  if (response) {
    router.push('/mobile/ads');
  }
}

function quotaNotificationAction () {
  quotaNotificationDisplay.value = false;
}

function sendEvent (event) {
  const events = {
    'btn-owners': {
      event: 'select_content',
      content_type: 'botao',
      creative_slot: 'empreendimento',
      creative_name: 'proprietários',
      level_name: 'lead',
    },
    'btn-transactions': {
      event: 'select_content',
      content_type: 'botao',
      creative_slot: 'empreendimento',
      creative_name: 'transacionados',
      level_name: 'lead',
    },
    'btn-more': {
      event: 'select_content',
      content_type: 'botao',
      creative_slot: 'transacionados',
      creative_name: 'ver_mais',
      level_name: 'lead',
    },
  };

  Activity.send(events[event]);
}

function changePanelVisibility (panelName, button) {
  switch (button) {
  case true:
    for (const panelKey of Object.keys(panel.value)) {
      if (panelKey !== 'previousOffers') {
        panel.value[panelKey].open = false;
      }
    }

    panel.value[panelName].open = true;
    break;
  
  default:
    panel.value[panelName].open = !(panel.value[panelName].open);
    break;
  }
}

function increaseMax (offerType) {
  switch (offerType) {
  case 'bossaOffers':
    offersMax.value.bossaOffers += 4;
    break;
  case 'newOffers':
    offersMax.value.newOffers += 4;
    break;
  case 'lastMonthOffers':
    offersMax.value.lastMonthOffers += 4;
    break;
  }
}

function collapseAll (offerType) {
  switch (offerType) {
  case 'bossaOffers':
    offersMax.value.bossaOffers = 4;
    break;
  case 'newOffers':
    offersMax.value.newOffers = 4;
    break;
  case 'lastMonthOffers':
    offersMax.value.lastMonthOffers = 4;
    break;
  }
}
vuexStore.dispatch('user/fetchConsultaDadosQuota');

</script>

<template>
  <div
    class="condominium"
    :style="{ height: condominiumPageHeight, minHeight: condominiumPageHeight }"
  >
    <QuotaNotification
      :display="quotaNotificationDisplay"
      @quota-notification-click="() => quotaNotificationAction()"
    />
    <LGPDModal v-if="leadPermit" />
    <div
      v-if="transactionsModal"
      class="transactions-modal"
    >
      <div class="content">
        <div
          class="close-button"
          @click="hideTransactionsModal()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            class="mt-4"
          >
            <path
              d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
              fill="#1B2644"
            />
          </svg>
        </div>
        <div class="last-transaction flex flex-col">
          <div class="text-center">
            <Typography
              color="brand-dark"
              variant="title-medium"
              tag="span"
            >
              {{ transactionUnit[0].units }}
            </Typography>
          </div>
          <div class="flex flex-row w-full mt-2">
            <div class="flex flex-col gap-2 w-1/2 pr-2">
              <div class="flex flex-row justify-start">
                <Typography
                  color="neutral-grey-5"
                  variant="title-medium"
                  tag="div"
                >
                  Valor:&nbsp;
                </Typography>
                <Typography
                  color="brand-dark"
                  variant="title-medium"
                  tag="div"
                >
                  {{ formatCurrency(transactionUnit[0].value) }}
                </Typography>
              </div>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Valor venal: {{ formatCurrency(transactionUnit[0].referenceValue) }}
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Testada: {{ transactionUnit[0].frontArea }} m²
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Área terreno: {{ Intl.NumberFormat('pt-BR').format(transactionUnit[0].terrainArea) }} m²
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Contribuinte: {{ sqlFormatter(transactionUnit[0].sql) }}
              </Typography>
            </div>
            <div class="flex flex-col gap-2 pl-2">
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
                style="margin-top: 3px"
              >
                Data: {{ Intl.DateTimeFormat('pt-BR').format(transactionUnit[0].date) }}
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Matrícula: {{ transactionUnit[0].registryNumber }}
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Tipo: {{ transactionUnit[0].propertyType }}
              </Typography>
              <Typography
                color="brand-dark"
                variant="body-medium"
                tag="div"
              >
                Base cálculo: {{ formatCurrency(transactionUnit[0].calcBase) }}
              </Typography>
              <div class="flex flex-row justify-start">
                <Typography
                  color="brand-dark"
                  variant="body-medium"
                  tag="div"
                >
                  Tipo de transação:&nbsp;
                </Typography>
                <tooltip
                  :message="transactionUnit[0].type ? transactionUnit[0].type.split('.')[1] : '-'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                      fill="#E3D450"
                    />
                  </svg>
                </tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="previous-transactions">
          <div class="text-center">
            <Typography
              color="brand-dark"
              variant="title-medium"
              tag="span"
              class="mt-2"
            >
              Outras transações
            </Typography>
          </div>
          <div
            v-for="(unit, unitIndex) in transactionUnit"
            :key="unitIndex"
            class="flex flex-row w-full mt-2"
          >
            <template v-if="unitIndex">
              <div class="flex flex-col gap-2 w-1/2 pr-2">
                <div class="flex flex-row justify-start">
                  <Typography
                    color="neutral-grey-5"
                    variant="title-medium"
                    tag="div"
                  >
                    Valor:&nbsp;
                  </Typography>
                  <Typography
                    color="brand-dark"
                    variant="title-medium"
                    tag="div"
                  >
                    {{ formatCurrency(unit.value) }}
                  </Typography>
                </div>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  Valor venal: {{ formatCurrency(unit.referenceValue) }}
                </Typography>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  Testada: {{ unit.frontArea }} m²
                </Typography>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  Base cálculo: {{ formatCurrency(unit.calcBase) }}
                </Typography>
              </div>
              <div class="flex flex-col gap-2 pl-2">
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                  style="margin-top: 3px"
                >
                  Data: {{ Intl.DateTimeFormat('pt-BR').format(unit.date) }}
                </Typography>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  Matrícula: {{ unit.registryNumber }}
                </Typography>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  Área terreno: {{ Intl.NumberFormat('pt-BR').format(unit.terrainArea) }} m²
                </Typography>
                <div class="flex flex-row justify-start">
                  <Typography
                    color="neutral-grey-5"
                    variant="body-medium"
                    tag="div"
                  >
                    Tipo de transação:&nbsp;
                  </Typography>
                  <tooltip
                    :message="unit.type ? unit.type.split('.')[1] : '-'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                        fill="#E3D450"
                      />
                    </svg>
                  </tooltip>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!quotaNotificationDisplay">
      <div
        class="header"
        style="min-height: 64px;"
      >
        <div class="flex flex-row justify-between">
          <div class="px-4">
            <SmallButton
              v-if="route.path.includes('search') || (!propertyIsLoading && !typologiesIsLoading && !offersIsLoading && !ownersIsLoading && summary?.condominiumCount)"
              label="Voltar"
              :icon="ArrowBackIcon"
              class="my-4"
              @small-button-click="() => router.back()"
            />
          </div>
          <div class="px-4">
            <!-- // -->
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-grow justify-center w-full">
        <template
          v-if="step === 'search'"
        >
          <div class="px-4">
            <Typography
              class="my-4 text-center"
              color="brand-lighter"
              variant="header-xl"
              tag="h1"
            >
              Buscar imóvel
            </Typography>
          </div>
          <div class="pt-8 bg-white rounded-t-lg flex-grow">
            <Lead />
          </div>
        </template>

        <template v-else-if="step === 'list'">
          <template v-if="summary && Object.keys(summary).length && summary.condominiumCount">
            <div
              class="px-8 py-4 rounded-t-3xl border-t-2 border-solid gap-4 flex flex-col h-48"
              style="background-color: #1B2644; border-color: #C7D0EA;"
            >
              <Typography
                color="brand-lighter"
                variant="button-small"
                tag="h2"
                class="text-center"
              >
                Resumo dos resultados
              </Typography>
              <template v-if="summaryIsLoading">
                <div class="flex flex-row justify-center items-center">
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="div"
                  >
                    Carregando ...
                  </Typography>
                </div>
              </template>
              <template v-else>
                <div class="flex flex-row justify-start">
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="div"
                  >
                    {{ summary.condominiumCount }}
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="title-small-secondary"
                    tag="div"
                    class="pl-1"
                  >
                    Condomínios encontrados
                  </Typography>
                </div>
                <div class="flex flex-row justify-start">
                  <div class="flex flex-row justify-start">
                    <Typography
                      color="neutral-white"
                      variant="body-table"
                      tag="div"
                    >
                      {{ summary.condoWithOfferCount }}
                    </Typography>
                    <Typography
                      color="neutral-white"
                      variant="title-small-secondary"
                      tag="div"
                      class="pl-1"
                    >
                      Com oferta
                    </Typography>
                  </div>
                  <div class="flex flex-row justify-start">
                    <Typography
                      color="neutral-white"
                      variant="body-table"
                      tag="div"
                    >
                      {{ summary.condoWithNoOffersCount }}
                    </Typography>
                    <Typography
                      color="neutral-white"
                      variant="title-small-secondary"
                      tag="div"
                      class="pl-1"
                    >
                      Sem oferta
                    </Typography>
                  </div>
                </div>
                <div>
                  <hr
                    class="border-t"
                    style="border-color: #303A56"
                  >
                </div>
                <div class="flex flex-row justify-start">
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="div"
                  >
                    {{ summary.offerCount }}
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="title-small-secondary"
                    tag="div"
                    class="pl-1"
                  >
                    Ofertas encontradas
                  </Typography>
                </div>
                <div class="flex flex-row justify-start">
                  <Typography
                    color="neutral-white"
                    variant="body-table"
                    tag="div"
                  >
                    {{ summary.sellerCount }}
                  </Typography>
                  <Typography
                    color="neutral-white"
                    variant="title-small-secondary"
                    tag="div"
                    class="pl-1"
                  >
                    Imobiliárias com essas ofertas
                  </Typography>
                </div>
              </template>
            </div>
          </template>
          <div class="p-4 bg-white rounded-t-3xl flex-grow flex flex-col gap-4 h-full">
            <template v-if="resultsIsLoading || result.length < 1 ">
              <div class="flex flex-col justify-center items-center h-full">
                <Typography
                  color="brand-dark"
                  variant="title-medium"
                  tag="span"
                >
                  Carregando...
                </Typography>
              </div>
            </template>

            <template v-else>
              <template v-if="result && !Object.keys(result).length">
                <div
                  class="flex flex-wrap justify-center py-10 px-10"
                >
                  <div
                    class="flex flex-col items-center mt-8 gap-8"
                  >
                    <MaintenanceIcon />
                    <div class="flex flex-col items-center gap-4 text-center">
                      <Typography
                        color="brand-dark"
                        variant="title-large-secondary"
                      >
                        Sem resultados
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-large"
                      >
                        Por favor, tente outros parâmetros de procura para abranger mais resultados.
                      </Typography>
                    </div>
                    <Button
                      style="min-width: 0 !important; padding: 16px !important;"
                      variant="secondary"
                      @click="router.back()"
                    >
                      Refazer busca
                    </Button>
                  </div>
                </div>
              </template>

              <template v-else>
                <div
                  v-if="filteredResults?.length"
                  class="search-result__summary"
                >
                  <SearchFilters
                    @with-offers="onSearchFilterWithOffers"
                    @order-by="onSearchFilterOrderBy"
                  />
                </div>
                <div
                  v-for="condominium in filteredResults"
                  :key="condominium.id"
                >
                  <div
                    v-if="condominium.typology && condominium.typologiesFound"
                    class="rounded border-2 border-solid border-gray-300 p-4 flex flex-col gap-1"
                    @click="fetchProperty(condominium.addressId, condominium.adsIds, condominium.condoId)"
                  >
                    <Typography
                      color="brand-dark"
                      variant="title-medium"
                      tag="div"
                    >
                      {{ condominium.condo }}
                    </Typography>

                    <Typography
                      color="brand-dark"
                      variant="body-small"
                      tag="div"
                    >
                      {{ condominium.addressLine1 }} {{ condominium.addressLine2 }}
                    </Typography>

                    <div
                      v-if="condominium.typology"
                      class="flex flex-row justify-start my-2"
                    >
                      <div>
                        <div class="flex flex-col border-r border-gray-300 border-solid gap-1 text-center pr-2">
                          <Typography
                            color="highlight-default"
                            variant="label-xs"
                            tag="div"
                          >
                            {{ condominium.typology.utilArea[0] }} a {{ condominium.typology.utilArea[1] }} m²
                          </Typography>
                          <Typography
                            color="brand-dark"
                            variant="label-xs"
                            tag="div"
                          >
                            Área útil
                          </Typography>
                        </div>
                      </div>

                      <div>
                        <div class="flex flex-col border-r border-gray-300 border-solid gap-1 text-center px-2">
                          <Typography
                            color="highlight-default"
                            variant="label-xs"
                            tag="div"
                          >
                            {{ condominium.typology.bedRooms[0] }} a {{ condominium.typology.bedRooms[1] }}
                          </Typography>
                          <Typography
                            color="brand-dark"
                            variant="label-xs"
                            tag="div"
                          >
                            Dorms.
                          </Typography>
                        </div>
                      </div>

                      <div v-if="condominium?.typology?.suites">
                        <div class="flex flex-col border-r border-gray-300 border-solid gap-1 text-center px-2">
                          <Typography
                            color="highlight-default"
                            variant="label-xs"
                            tag="div"
                          >
                            {{ condominium.typology.suites[0] }} a {{ condominium.typology.suites[1] }}
                          </Typography>
                          <Typography
                            color="brand-dark"
                            variant="label-xs"
                            tag="div"
                          >
                            Suítes
                          </Typography>
                        </div>
                      </div>

                      <div v-if="condominium?.typology?.parkingLots">
                        <div class="flex flex-col gap-1 text-center pl-2">
                          <Typography
                            color="highlight-default"
                            variant="label-xs"
                            tag="div"
                          >
                            {{ condominium?.typology?.parkingLots[0] }} a {{ condominium?.typology?.parkingLots[1] }}
                          </Typography>
                          <Typography
                            color="brand-dark"
                            variant="label-xs"
                            tag="div"
                          >
                            Vagas
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Typography
                      v-if="condominium.typologiesFound"
                      color="brand-dark"
                      variant="label-xs"
                      tag="div"
                    >
                      Tipologias encontradas:
                    </Typography>
                    <Typography
                      v-if="condominium.typologiesFound"
                      color="brand-dark"
                      variant="label-xs"
                      tag="div"
                    >
                      {{ condominium.typologiesFound.utilArea[0] }} m² ~ 
                      {{ condominium.typologiesFound.utilArea[1] }} m² | 
                      <template v-if="condominium?.typologiesFound?.value">
                        {{ condominium?.typologiesFound?.value?.length ? formatCurrency(condominium.typologiesFound.value[0]) : '-' }} ~ 
                        {{ condominium?.typologiesFound?.value?.length ? formatCurrency(condominium.typologiesFound.value[1]) : '-' }} | 
                      </template>
                      <Typography
                        tag="span"
                        variant="label-xs"
                        :color="condominium.offersCount ? 'feedback-success' : 'brand-dark' "
                      >
                        {{ condominium.offersCount }} ofertas
                      </Typography>
                    </Typography>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>

        <template v-else>
          <template v-if="property && Object.keys(property).length">
            <div class="px-4">
              <Typography
                class="mb-4 text-left"
                color="brand-lighter"
                variant="header-xl"
                tag="h1"
              >
                {{ property.condoName }}
              </Typography>
            </div>

            <div class="p-4 bg-white rounded-t-2xl flex-grow flex flex-col">
              <template v-if="propertyIsLoading">
                <Loading />
              </template>
              <template v-else>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  {{ property.addressLine1 }}
                </Typography>
                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                >
                  {{ property.addressLine2 }}
                </Typography>

                <img
                  :src="property.imageDefault"
                  alt=""
                  class="rounded-2xl my-4"
                >

                <template v-if="property && Object.keys(property).length">
                  <div class="flex flex-row w-full justify-between gap-2">
                    <Button
                      style="min-width: 0 !important; width: 50% !important; padding: 16px !important;"
                      tag="a"
                      variant="primary"
                      href="#owners"
                      @click="() => {
                        sendEvent('btn-owners')
                        changePanelVisibility('owners', true)
                      }"
                    >
                      Proprietários
                    </Button>
                    <Button
                      style="min-width: 0 !important; width: 50% !important; padding: 16px !important;"
                      tag="a"
                      variant="primary"
                      href="#transactions"
                      @click="() => {
                        sendEvent('btn-transactions')
                        changePanelVisibility('transactions', true)
                      }"
                    >
                      Transacionados
                    </Button>
                  </div>
                </template>

                <template v-if="property?.amenitiesCondominium?.length">
                  <Characteristics :characteristics="property.amenitiesCondominium" />
                </template>

                <template v-if="property?.iptuDescription">
                  <IPTU :iptu="property.iptuDescription" />
                </template>
              </template>

              <template v-if="typologiesIsLoading">
                <Loading />
              </template>
              <template v-else>
                <Typography
                  color="brand-dark"
                  variant="title-large-secondary"
                  tag="div"
                  class="mt-8"
                >
                  Tipologia tipo e valores
                </Typography>

                <Typography
                  color="neutral-grey-5"
                  variant="body-medium"
                  tag="div"
                  class="mt-4"
                >
                  Unidades padrões do condomínio, com maior número de repetições
                </Typography>

                <template
                  v-if="typologies?.rows?.length"
                >
                  <div
                    v-for="typology in typologies.rows.filter(row => row.default === 1)"
                    :key="typology.typology_id"
                    class="rounded-lg p-4 flex flex-col gap-2 mt-4"
                    style="background-color: #f3f5fb;"
                  >
                    <Typography
                      color="brand-dark"
                      variant="title-medium"
                      tag="div"
                    >
                      {{ typology.propertyType }}
                    </Typography>
                    <div class="flex flex-row">
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"
                        class="pr-2 border-r border-gray-500 border-solid"
                      >
                        Área útil {{ typology.area }} m²
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"
                        class="px-2 border-r border-gray-500 border-solid"
                      >
                        {{ typology.bedrooms }} dorms.
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"
                        class="px-2 border-r border-gray-500 border-solid"
                      >
                        {{ typology.suites }} Suítes
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"
                        class="pl-2 "
                      >
                        {{ typology?.parkingLots }} Vagas 
                      </Typography>
                    </div>

                    <div class="flex flex-row justify-start mt-4">
                      <div>
                        <div class="flex flex-col gap-2 border-r border-gray-500 border-solid pr-4">
                          <Typography
                            color="brand-dark"
                            variant="label-small"
                            tag="div"
                          >
                            Valor ofertado
                          </Typography>

                          <Typography
                            color="highlight-default"
                            variant="label-small"
                            tag="div"
                          >
                            {{ formatCurrency(typology.offerPrice) }}
                          </Typography>
                        </div>
                      </div>

                      <div>
                        <div class="flex flex-col gap-2 px-4">
                          <Typography
                            color="brand-dark"
                            variant="label-small"
                            tag="div"
                          >
                            Valor AVM
                          </Typography>

                          <Typography
                            color="highlight-default"
                            variant="label-small"
                            tag="div"
                          >
                            {{ typology.market.price ? formatCurrency(typology.market.price) : 'N/I' }}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row justify-start gap-2 mt-4">
                      <div
                        style="border-radius: 9999px; background-color: #768AC6;"
                        class="py-2 px-4"
                      >
                        <Typography
                          color="neutral-white"
                          variant="label-small"
                          tag="div"   
                        >
                          {{ typology.offerCount }} anúncios
                        </Typography>
                      </div>

                      <div
                        style="border-radius: 9999px; background-color: #D3D6DE;"
                        class="py-2 px-4"
                      >
                        <Typography
                          color="brand-dark"
                          variant="label-small"
                          tag="div"   
                        >
                          {{ parseInt(typology.rate) }}% do total de anúncios
                        </Typography>
                      </div>
                    </div>
                  </div>
                </template>
              </template>

              <!-- Ofertas -->

              <template v-if="offersIsLoading">
                <Loading />
              </template>
              <template v-else>
                <div
                  v-if="offers && offers.length"
                  class="flex flex-col"
                >
                  <div
                    v-if="bossaOffers.length"
                    class="flex flex-col gap-4 mt-8 mb-4"
                  >
                    <div class="d-flex justify-center flex-column gap-1">
                      <Typography
                        color="highlight-default"
                        variant="title-large-secondary"
                        tag="div"   
                      >
                        {{ offersInfo.bossaOffers.title }}
                      </Typography>
                      <Typography
                        color="highlight-default"
                        variant="body-medium"
                        tag="div"   
                      >
                        {{ offersInfo.bossaOffers.description }}
                      </Typography>
                    </div>
                    <OfferCard
                      v-for="(offer, index) in bossaOffers"
                      v-if="index < offersMax.bossaOffers"
                      :key="offer.offerId"
                      :offer="offer"
                      @see-ads="(evt) => seeAds(evt.id, evt.ads, evt.typology)"
                    />
                    <div class="d-flex align-center justify-center w-full mb-5">
                      <v-btn
                        v-if="bossaOffers.length > offersMax.bossaOffers"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-more-bossa"
                        @click="() => increaseMax('bossaOffers')"
                      >
                        <span class="button-text"> Ver mais {{ offersInfo.bossaOffers.title }} </span>
                      </v-btn>
                      <v-btn
                        v-if="bossaOffers.length <= offersMax.bossaOffers && offersMax.bossaOffers > offerMin"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-less-bossa"
                        @click="collapseAll('bossaOffers')"
                      >
                        <span class="button-text">Ver menos {{ offersInfo.bossaOffers.title }}</span>
                      </v-btn>
                    </div>
                  </div>

                  <div
                    v-if="newsOffers.length"
                    class="flex flex-col gap-4 mt-0 mb-4"
                  >
                    <div class="d-flex justify-center flex-column gap-1">
                      <Typography
                        color="brand-dark"
                        variant="title-large-secondary"
                        tag="div"   
                      >
                        {{ offersInfo.newOffers.title }}
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"   
                      >
                        {{ offersInfo.newOffers.description }}
                      </Typography>
                    </div>
                    <OfferCard
                      v-for="(offer, index) in newsOffers"
                      v-if="index < offersMax.newOffers"
                      :key="offer.offerId"
                      :offer="offer"
                      @see-ads="(evt) => seeAds(evt.id, evt.ads, evt.typology)"
                    />
                    <div class="d-flex align-center justify-center w-full mb-5">
                      <v-btn
                        v-if="newsOffers.length > offersMax.newOffers"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-more-new"
                        @click="() => increaseMax('newOffers')"
                      >
                        <span class="button-text"> Ver mais ofertas em {{ offersInfo.newOffers.title }} </span>
                      </v-btn>
                      <v-btn
                        v-if="newsOffers.length <= offersMax.newOffers && offersMax.newOffers > offerMin"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-less-new"
                        @click="collapseAll('newOffers')"
                      >
                        <span class="button-text">Ver menos ofertas em {{ offersInfo.newOffers.title }}</span>
                      </v-btn>
                    </div>
                  </div>

                  <div
                    v-if="remainedOffers.length"
                    class="flex flex-col gap-4 mt-0 mb-4"
                  >
                    <div class="d-flex justify-center flex-column gap-1">
                      <Typography
                        color="brand-dark"
                        variant="title-large-secondary"
                        tag="div"   
                      >
                        {{ offersInfo.lastMonthOffers.title }}
                      </Typography>
                      <Typography
                        color="brand-dark"
                        variant="body-medium"
                        tag="div"   
                      >
                        {{ offersInfo.lastMonthOffers.description }}
                      </Typography>
                    </div>
                    <OfferCard
                      v-for="(offer, index) in remainedOffers"
                      v-if="index < offersMax.lastMonthOffers"
                      :key="offer.offerId"
                      :offer="offer"
                      @see-ads="(evt) => seeAds(evt.id, evt.ads, evt.typology)"
                    />
                    <div class="d-flex align-center justify-center w-full mb-5">
                      <v-btn
                        v-if="remainedOffers.length > offersMax.lastMonthOffers"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-more-last-month"
                        @click="() => increaseMax('lastMonthOffers')"
                      >
                        <span class="button-text"> Ver mais ofertas em {{ offersInfo.lastMonthOffers.title }} </span>
                      </v-btn>
                      <v-btn
                        v-if="remainedOffers.length <= offersMax.lastMonthOffers && offersMax.lastMonthOffers > offerMin"
                        depressed
                        outlined
                        rounded
                        color="#3566C9"
                        data-test="see-less-las-month"
                        @click="collapseAll('lastMonthOffers')"
                      >
                        <span class="button-text">Ver menos ofertas em {{ offersInfo.lastMonthOffers.title }}</span>
                      </v-btn>
                    </div>
                  </div>

                  <div
                    v-if="potentialOffers.length"
                    class="flex flex-col gap-4"
                  >
                    <ExpandablePanel
                      id="potential-offers"
                      class="my-3"
                      :offers-info="offersInfo.potentialOffers"
                      :open="panel.potentialOffers.open"
                      @panel-expand="() => changePanelVisibility('potentialOffers', false)"
                    >
                      <OfferCard
                        v-for="offer in potentialOffers"
                        :key="offer.offerId"
                        :offer="offer"
                        @see-ads="(evt) => seeAds(evt.id, evt.ads, evt.typology)"
                      />
                    </ExpandablePanel>
                  </div>

                  <div
                    v-if="previousOffers.length"
                    class="flex flex-col gap-4"
                  >
                    <ExpandablePanel
                      id="previous-offers"
                      class="my-3"
                      :offers-info="offersInfo.previousOffers"
                      :open="panel.previousOffers.open"
                      @panel-expand="() => changePanelVisibility('previousOffers', false)"
                    >
                      <OfferCard
                        v-for="offer in previousOffers"
                        :key="offer.offerId"
                        :offer="offer"
                        @see-ads="(evt) => seeAds(evt.id, evt.ads, evt.typology)"
                      />
                    </ExpandablePanel>
                  </div>
                </div>
              </template>

              <!-- Final Ofertas -->

              <ExpandablePanel
                id="owners"
                title="Proprietários"
                class="my-4"
                :open="panel.owners.open"
                tracker="proprietários_accordion"
                @panel-expand="() => changePanelVisibility('owners', false)"
              >
                <template v-if="ownersIsLoading">
                  <Loading />
                </template>
                <template v-else>
                  <template v-if="leadPermit">
                    <template v-if="owners?.length">
                      <table>
                        <thead class="border-b border-gray-500 border-solid">
                          <tr>
                            <th class="w-2/5">
                              <Typography
                                color="neutral-grey-5"
                                variant="title-medium"
                                tag="div"
                                class="my-4 text-left w-2/5"
                              >
                                Unidade
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                color="neutral-grey-5"
                                variant="title-medium"
                                tag="div"
                                class="my-4 text-left"
                              >
                                Proprietários
                              </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(owner, id) in owners"
                            :key="id"
                            class="border-b border-gray-500 border-solid"
                          >
                            <td>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                                class="mt-4"
                              >
                                {{ owner.unit }}
                              </Typography>
                            </td>
                            <td>
                              <div>
                                <Typography
                                  color="brand-dark"
                                  variant="body-medium"
                                  tag="div"
                                  class="mt-4"
                                >
                                  Contribuinte: {{ owner.taxPayerNumber }}
                                </Typography>
                              </div>
                              <div
                                v-for="el in owner.details"
                                :key="el.documento"
                              >
                                <Typography
                                  v-if="!el.isBlocked"
                                  color="brand-dark"
                                  variant="body-medium"
                                  tag="div"
                                  class="mt-4"
                                >
                                  {{ el.order }}° {{ el.name }} 
                                </Typography>
                                <SmallButton
                                  v-if="!el.isBlocked"
                                  label="Consultar dados"
                                  :icon="AnalyticsSmall"
                                  class="bg-white my-2 mt-4"
                                  variant="consulta-dados"
                                  @small-button-click="() => ownerSearch(el.document)"
                                />
                                <BlockedInfoMessage
                                  v-if="el.isBlocked"  
                                  class="lgpd-message mb-4 mt-4"
                                  message="Dados bloqueados a pedido do titular de acordo com a Lei Geral de Proteção de Dados (LGPD)"
                                />
                              </div>                              
                              <div
                                v-if="owner.details.length === 0"
                                class="mb-4"
                              >
                                <Typography
                                  color="neutral-grey-5"
                                  variant="helper"
                                  tag="span"
                                >
                                  Encontrou informações sobre esse proprietário? Envie um e-mail para:
                                  <br>
                                  <Typography
                                    color="highlight-default"
                                    variant="helper"
                                    tag="a"
                                    :href="`mailto:${email}?subject=%5B${owner.taxPayerNumber}%5D%20%7C%20Indica%C3%A7%C3%A3o%20de%20propriet%C3%A1rio%20do%20empreendimento%20${property.condoName}&body=Dados%3A%20%0D%0A%0D%0AEndere%C3%A7o%3A%20${address()}%0D%0A%0D%0ANome%20do%20propriet%C3%A1rio%201%3A%20%20%0D%0ACPF%20do%20propriet%C3%A1rio%201%3A%20%0D%0A%0D%0ANome%20do%20propriet%C3%A1rio%202%3A%20%20%0D%0ACPF%20do%20propriet%C3%A1rio%202%3A%20`"
                                    target="_blank"
                                    style="text-decoration: underline;"
                                  >
                                    {{ email }}
                                  </Typography>
                                </Typography>
                                <a
                                  href="https://www.prefeitura.sp.gov.br/cidade/secretarias/fazenda/servicos/certidoes/index.php?p=2395"
                                  target="_blank"
                                  class="search"
                                >
                                  <SmallButton
                                    label="Consultar IPTU"
                                    :icon="SearchIcon"
                                    class="bg-white my-2 mt-4"
                                    variant="consulta-dados"
                                  />
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                    <template v-else>
                      <NotFound />
                    </template>
                  </template>
                </template>
              </ExpandablePanel>
              <ExpandablePanel
                id="transactions"
                title="Transacionados"
                class="my-4"
                :open="panel.transactions.open"
                tracker="transacionados_accordion"
                @panel-expand="() => changePanelVisibility('transactions', false)"
              >
                <template v-if="transactionsLoading">
                  <Loading />
                </template>
                <template v-else>
                  <template v-if="Object.keys(transactions).length">
                    <table class="w-full">
                      <thead class="border-b border-gray-500 border-solid">
                        <tr>
                          <th class="w-1/2">
                            <Typography
                              color="neutral-grey-5"
                              variant="title-medium"
                              tag="div"
                              class="my-4 text-left w-1/2"
                            >
                              Unidade
                            </Typography>
                          </th>
                          <th>
                            <Typography
                              color="neutral-grey-5"
                              variant="title-medium"
                              tag="div"
                              class="my-4 text-left"
                            >
                              Valor
                            </Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(transaction, transactionId) in transactions"
                          :key="transactionId"
                          class="border-b border-gray-500 border-solid"
                        >
                          <td>
                            <div class="flex flex-col gap-2 py-4 pr-2">
                              <Typography
                                color="brand-dark"
                                variant="title-medium"
                                tag="div"
                              >
                                {{ transaction[0].units }}
                              </Typography>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Data: {{ Intl.DateTimeFormat('pt-BR').format(transaction[0].date) }}
                              </Typography>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Matrícula: {{ transaction[0].registryNumber }}
                              </Typography>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Tipo: {{ transaction[0].propertyType }}
                              </Typography>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Contribuinte: {{ sqlFormatter(transaction[0].sql) }}
                              </Typography>

                              <div class="flex flex-row justify-start">
                                <Typography
                                  color="brand-dark"
                                  variant="body-medium"
                                  tag="div"
                                >
                                  Tipo de transação:&nbsp;
                                </Typography>
                                <tooltip
                                  :message="transaction[0].type ? transaction[0].type.split('.')[1] : '-'"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                                      fill="#E3D450"
                                    />
                                  </svg>
                                </tooltip>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="flex flex-col gap-2 py-4">
                              <div class="flex flex-row justify-start">
                                <Typography
                                  color="neutral-grey-5"
                                  variant="title-medium"
                                  tag="div"
                                >
                                  Valor:&nbsp;
                                </Typography>
                                <Typography
                                  color="brand-dark"
                                  variant="title-medium"
                                  tag="div"
                                >
                                  {{ formatCurrency(transaction[0].value) }}
                                </Typography>
                              </div>
                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Valor venal: {{ formatCurrency(transaction[0].referenceValue) }}
                              </Typography>

                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Testada: {{ transaction[0].frontArea }} m²
                              </Typography>

                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Área terreno: {{ Intl.NumberFormat('pt-BR').format(transaction[0].terrainArea) }} m²
                              </Typography>

                              <Typography
                                color="brand-dark"
                                variant="body-medium"
                                tag="div"
                              >
                                Base cálculo: {{ formatCurrency(transaction[0].calcBase) }}
                              </Typography>
                              <div
                                v-if="transaction.length > 1"
                                style="padding: 5px 8px; background-color: #BDCCF8; border-radius: 9999px; max-width: 100px;"
                                class="flex justify-center"
                                @click="showTransactionsModal(transaction)"
                              >
                                <Typography
                                  color="brand-default"
                                  variant="label-xs"
                                  tag="div"
                                  @click="sendEvent('btn-more')"
                                >
                                  Mais transações
                                </Typography>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-else>
                    <NotFound />
                  </template>
                </template>
              </ExpandablePanel>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.condominium {
  position: absolute;
  top: 0;
  left: 0;

  width: clamp(100%, 100%, 100vw);
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1B2644;

  z-index: 999;
}
.transactions-modal {
  position: sticky;
  top: 0;
  left: 0;

  width: clamp(100%, 100%, 100vw);
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.16);
  padding: 8px;

  z-index: 1999;

  .content {
    width: 100%;
    max-height: 615px;
    background-color: #fff;
    margin: auto 0;
    border-radius: 20px;

    .close-button {
      position: absolute;
      right: 24px;
    }
    .last-transaction {
      background-color: #F4F4F4;
      padding: 16px 24px 8px 24px;
      border-radius: 20px 20px 0 0;
      border-bottom: 2px solid #D3D6DE;
    }
    .previous-transactions {
      padding: 16px 24px 8px 24px;
      border-bottom: none;
      margin-bottom: 8px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
}
.search {
  color: #6B8CEF;
}
.button-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: none;
}
</style>

<style lang="scss">
#main-content {
  scroll-behavior: smooth;
}
</style>
